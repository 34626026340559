import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ErrorBoundary from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';
import BaseSecureRouteWrapper from '@common/react/components/Routes/SecureRoute/BaseSecureRouteWrapper';

import DbaLoader from '@app/components/UI/DbaLoader/DbaLoader';

const params = {
	fallback: <DbaLoader />,
};

const DashboardLayout = loadable(() => loadableDelay(/* webpackChunkName: "DashboardLayout" */
	import('@app/components/UI/DashboardLayout/DashboardLayout'),
), params);

interface Props extends RouteProps {
	component: any;
	redirectPath?: string;
	title?: string;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', title, ...rest
}) => {
	return <Route
		{...rest}
		render={(props) => <BaseSecureRouteWrapper path={rest.path as string} redirectPath={redirectPath}>
			<DashboardLayout>
				{title && <Helmet>
					<title>{title}</title>
				</Helmet>}
				<ErrorBoundary>
					<Component {...props} />
				</ErrorBoundary>
			</DashboardLayout>
		</BaseSecureRouteWrapper>}
	/>;
};

export default DashboardRoute;
