import { ReducersMapObject } from 'redux';

import * as Item from '@common/react/store/Item';
import { BaseApplicationState, BaseAppThunkAction, baseReducers } from '@common/react/store';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';

import { SecureRoutesPropsPagesMap } from '@app/components/Routes/SecureRoute/SecureRoutesPropsPagesMap';
import { User } from '@app/objects/User';
import { BuildData } from '@app/objects/BuildData';
import { HostOptions } from '@app/objects/HostOptions';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
	serverPage: PageItemState<any>;
	buildData: Item.ItemState<BuildData>;
	hostOptions: Item.ItemState<HostOptions>;
	routesPropsPagesMap: Item.ItemState<SecureRoutesPropsPagesMap>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,
	serverPage: PageStateReducer<any>('serverPage'),
	buildData: Item.getReducer<BuildData>('buildData'),
	hostOptions: Item.getReducer<HostOptions>('hostOptions'),
	routesPropsPagesMap: Item.getReducer<SecureRoutesPropsPagesMap>('routesPropsPagesMap'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = BaseAppThunkAction<TAction, User, ApplicationState>
